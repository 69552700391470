const QUIZ_VERSION = 'quiz_version';
enum QuizVersionValues {
  V1 = 'v1',
  V3 = 'quiz_v3',
  V4 = 'quiz_v4',
  V5 = 'quiz_v5',
}

const QUIZ_FLOW = 'quiz_flow';
enum QuizFlowValues {
  PAYMENT = 'payment',
  DOWNLOAD_APP = 'download_app',
}

const LOADER_VERSION = 'loader_version';
enum LoaderVersionValues {
  V1 = 'loader_v1',
  V2 = 'loader_v2',
}

const EMAIL_VERSION = 'email_version';
enum EmailVersionValues {
  V1 = 'email_v1',
  V2 = 'email_v2',
}

const PAYWALL_CTA = 'paywall_cta';
const PAYWALL_CTA_NO_TRIAL = 'paywall_cta_no_trial';
const PAYWALL_CTA_ACTION = 'paywall_cta_action';
const PAYWALL_PLANS = 'paywall_plans';
const PAYWALL_PLANS_NO_TRIAL = 'paywall_plans_no_trial';
const PAYWALL_SHORT_FLOW_PLANS = 'paywall_short_flow_plans';
const PAYWALL_REFRESH_PLANS = 'refresh_paywall_offer_plans';
const PAYWALL_REFRESH_ACTION = 'refresh_paywall_action';

enum PaywallRefreshActionValues {
  NONE = 'none',
  DOWNLOAD_V1 = 'refresh_download_v1',
  DOWNLOAD_PAY_V1 = 'refresh_download_pay_v1',
}

const PAYWALL_APP_NAV_COMPONENT = 'paywall_app_nav_component';
enum PaywallAppNavComponentValues {
  APP_BUTTON = 'app_button',
  LIMITED_LINK = 'limited_link',
  NONE = 'none',
}

const PAYWALL_VERSION = 'paywall_version';
enum PaywallVersionValues {
  V1 = 'v1',
  V1_NUDE_1 = 'paywall_v1_nude_1',
  V1_NUDE_2 = 'paywall_v1_nude_2',
  UNDRESS_V1 = 'paywall_undress_v1',
  V2 = 'v2',
  V4 = 'paywall_v4',
  V4_NO_TRIAL = 'paywall_v4_no_trial',
  V4_NO_TRIAL_EXTERNAL = 'paywall_v4_no_trial_external',
}

const BACK_NAVIGATION_VERSION = 'back_navigation_version';
enum BackNavigationVersionValues {
  V1 = 'back_navigation_v1',
  V2 = 'back_navigation_v2',
}

const CHECKOUT_VERSION = 'checkout_version';
enum CheckoutVersionValues {
  V1 = 'checkout_v1',
  SLIMKIT = 'checkout_slimkit',
  ZYNG = 'checkout_zyng',
  KEGEL = 'checkout_kegel',
}

const SUCCESS_PAGE_VERSION = 'success_page_version';
enum SuccessPageVersionValues {
  V1 = 'v1',
  V2 = 'success_page_v2',
  V3 = 'success_page_v3',
}

const PAYMENT_ERROR_DISPLAY = 'payment_error_display';
enum PaymentErrorDisplayValues {
  PAGE = 'page',
  TOAST = 'toast',
  POPUP = 'popup',
}

const CLOSE_PAYWALL_ALLOWANCE = 'is_allow_close_paywall';
const IS_ALLOW_DOWNLOAD_APP_FROM_PAYWALL = 'is_allow_download_app_from_paywall';
const IS_PWA_FLOW = 'is_pwa_flow';

export {
  QUIZ_VERSION,
  QuizVersionValues,
  QUIZ_FLOW,
  QuizFlowValues,
  LOADER_VERSION,
  LoaderVersionValues,
  EMAIL_VERSION,
  EmailVersionValues,
  PAYWALL_CTA,
  PAYWALL_CTA_NO_TRIAL,
  PAYWALL_CTA_ACTION,
  PAYWALL_PLANS,
  PAYWALL_PLANS_NO_TRIAL,
  PAYWALL_SHORT_FLOW_PLANS,
  PAYWALL_REFRESH_PLANS,
  PAYWALL_REFRESH_ACTION,
  PAYWALL_APP_NAV_COMPONENT,
  PaywallRefreshActionValues,
  PaywallAppNavComponentValues,
  PAYWALL_VERSION,
  PaywallVersionValues,
  BACK_NAVIGATION_VERSION,
  BackNavigationVersionValues,
  CHECKOUT_VERSION,
  CheckoutVersionValues,
  SUCCESS_PAGE_VERSION,
  SuccessPageVersionValues,
  PAYMENT_ERROR_DISPLAY,
  PaymentErrorDisplayValues,
  CLOSE_PAYWALL_ALLOWANCE,
  IS_ALLOW_DOWNLOAD_APP_FROM_PAYWALL,
  IS_PWA_FLOW,
};
