import React, { ComponentType, createContext, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { Elements } from '@stripe/react-stripe-js';
import { CanMakePaymentResult, loadStripe, Stripe } from '@stripe/stripe-js';

import { ConfigResponse, webApiService } from '../../services/web-api';
import { Plan } from '../../types/plan';
import { useAnalytic } from '../analytic';
import { getLSValue, setLSValue } from '../../utils/locale-storage';
import { USER_DATA } from '../../constants/common';
import { UserLocalStorageData } from '../../types/user';
import { CheckoutKegel } from './checkouts/checkout-kegel';
import { CheckoutV1 } from './checkouts/checkout-v1';
import { addBodyScroll, removeBodyScroll } from './helpers/handle-body-scroll';
import { getPaymentRequestOptions, getStipeElementsOptions } from './helpers/get-payment-options';
import { CheckoutZyng } from './checkouts/checkout-zyng';
import { CheckoutSlimkit } from './checkouts/checkout-slimkit';
import { CheckoutVersionValues, CHECKOUT_VERSION } from '../../services/growthbook/features';
import { growthbook } from '../../services/growthbook/growthbook';

import styles from './payment.module.scss';

type CheckoutConfig<Props> = {
  CheckoutComponent: ComponentType<Props>;
  checkoutAnimation: 'slide-up' | 'fade-in';
};

const CHECKOUTS: Record<
  CheckoutVersionValues,
  CheckoutConfig<{ paymentConfig: ConfigResponse; plan: Plan; onCheckoutClose: () => void }>
> = {
  [CheckoutVersionValues.SLIMKIT]: {
    CheckoutComponent: CheckoutSlimkit,
    checkoutAnimation: 'slide-up',
  },
  [CheckoutVersionValues.ZYNG]: {
    CheckoutComponent: CheckoutZyng,
    checkoutAnimation: 'slide-up',
  },
  [CheckoutVersionValues.KEGEL]: {
    CheckoutComponent: CheckoutKegel,
    checkoutAnimation: 'slide-up',
  },
  [CheckoutVersionValues.V1]: {
    CheckoutComponent: CheckoutV1,
    checkoutAnimation: 'fade-in',
  },
};

type AlternativePayStatus = {
  supported: CanMakePaymentResult | null;
  status: 'pending' | 'settled';
};

type ContextStateType = {
  paymentConfig?: ConfigResponse;
  alternativePayStatus: AlternativePayStatus;
  handleOpenCheckout: () => Promise<void>;
};

const PaymentContext = createContext<ContextStateType>({
  paymentConfig: undefined,
  alternativePayStatus: { supported: null, status: 'pending' },
  handleOpenCheckout: async () => {},
});

type PaymentContextProviderProps = {
  children?: React.ReactNode;
  currentPlan: Plan;
};

const PaymentContextProvider: React.FC<PaymentContextProviderProps> = ({ children, currentPlan }) => {
  const currentUserData = getLSValue(USER_DATA, true) as UserLocalStorageData;
  const checkoutVersion = growthbook.getFeatureValue(
    CHECKOUT_VERSION,
    CheckoutVersionValues.KEGEL,
  ) as CheckoutVersionValues;

  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [paymentConfig, setPaymentConfig] = useState<ConfigResponse>();
  const [alternativePayStatus, setAlternativePayStatus] = useState<AlternativePayStatus>({
    supported: null,
    status: 'pending',
  });

  const { sendAmplitudeEvent, trackFBEvent } = useAnalytic();

  const paywallScreenEventSent = useRef(false);

  useEffect(
    () => () => {
      addBodyScroll(); // return body scroll if the component is unmounted
    },
    [],
  );

  useEffect(() => {
    (async () => {
      if (!currentUserData?.user_id) {
        return;
      }

      try {
        const config = await webApiService.getPaymentConfig({
          app: process.env.REACT_APP_NAME_APP || '',
          customer_id: currentUserData.user_id,
        });
        setPaymentConfig(config);
      } catch (error: unknown) {
        console.error('Error getting payment config:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [currentUserData?.user_id]);

  useEffect(() => {
    if (!paymentConfig) {
      return;
    }

    (async () => {
      try {
        const loadedStripe = await loadStripe(paymentConfig.checkout.stripe.publishable_key);

        if (!loadedStripe) {
          throw new Error('Stripe failed to load');
        }

        setStripe(loadedStripe);
      } catch (error: unknown) {
        console.error('Error getting stripe:', error);
      }
    })();
  }, [paymentConfig]);

  useEffect(() => {
    if (!stripe || !currentPlan || alternativePayStatus.status === 'settled') {
      return;
    }

    (async () => {
      try {
        const paymentRequest = stripe.paymentRequest(getPaymentRequestOptions(currentPlan));

        const canMakePayment = await paymentRequest.canMakePayment();
        setAlternativePayStatus({ supported: canMakePayment, status: 'settled' });
      } catch (error: unknown) {
        console.error('Error checking Apple/Google Pay support:', error);
        setAlternativePayStatus({ supported: null, status: 'settled' });
      }
    })();
  }, [alternativePayStatus.status, stripe, currentPlan]);

  const handleOpenCheckout = async () => {
    // TODO! Should be removed
    const userData = getLSValue(USER_DATA, true) as UserLocalStorageData;
    setLSValue(USER_DATA, { ...userData, plan: currentPlan });
    //

    removeBodyScroll();
    setIsCheckoutOpen(true);
    await trackFBEvent({ name: 'show_checkout' });

    if (!paywallScreenEventSent.current) {
      paywallScreenEventSent.current = true;

      sendAmplitudeEvent({
        name: 'show_payment',
      });
    }
  };

  const handleCloseCheckout = () => {
    addBodyScroll();
    setIsCheckoutOpen(false);
  };

  const { CheckoutComponent, checkoutAnimation } = CHECKOUTS[checkoutVersion];

  return (
    <PaymentContext.Provider value={{ paymentConfig, alternativePayStatus, handleOpenCheckout }}>
      <>
        {!isLoading && (
          <>
            {paymentConfig && (
              <Elements
                stripe={loadStripe(paymentConfig.checkout.stripe.publishable_key)}
                options={getStipeElementsOptions(currentPlan)}
              >
                {children}

                <div
                  className={clsx(styles.checkout, styles[checkoutAnimation], {
                    [styles.is_open]: isCheckoutOpen,
                  })}
                >
                  <div className={styles.checkout__backdrop} />
                  <div className={clsx(styles.checkout__content)}>
                    <CheckoutComponent
                      paymentConfig={paymentConfig}
                      plan={currentPlan}
                      onCheckoutClose={handleCloseCheckout}
                    />
                  </div>
                </div>
              </Elements>
            )}
          </>
        )}
      </>
    </PaymentContext.Provider>
  );
};

const usePaymentContext = (): ContextStateType => {
  const modalContext = useContext<ContextStateType>(PaymentContext);

  if (!modalContext) {
    throw Error('usePaymentContext hook should be wrapped by PaymentContextProvider');
  }

  return modalContext;
};

export { PaymentContextProvider, usePaymentContext };
